import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Nav from '../../components/Nav';
import { getUserTopUps } from '../../actions/topUpActions';
import {
  getUserDetails,
  updateUser,
  updateUserProfile,
} from '../../actions/userActions';

import { getUserOrders } from '../../actions/orderActions';
import { USER_UPDATE_RESET } from '../../constants/userConstants';

const EditUser = ({ match, history }) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { user, success: userSuccess } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userUpdate = useSelector((state) => state.userUpdate);
  const { success } = userUpdate;
  const getuserOrders = useSelector((state) => state.userOrders);
  const { orders } = getuserOrders;
  const getuserTopUps = useSelector((state) => state.userTopUps);
  const { topUps } = getuserTopUps;

  const [email, setEmail] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastName] = useState();
  const [nixx, setNixx] = useState();
  const [admin, setAdmin] = useState();
  const [showOrders, setShowOrders] = useState(true);
  const [showTopUps, setShowTopUps] = useState(true);
  useEffect(() => {
    if (!userInfo?.isAdmin) {
      history.push('/inloggen');
    } else {
      dispatch(getUserOrders(match.params.id));
      dispatch(getUserTopUps(match.params.id));
      dispatch(getUserDetails(match.params.id));
    }
    if (!topUps) {
      dispatch(getUserTopUps(match.params.id));
    }

    if (userSuccess) {
      setEmail(user.email);
      setFirstname(user.firstName);
      setLastName(user.lastName);
      setNixx(user.Nixx);
      setAdmin(user.isAdmin);
    }

    if (success) {
      history.push('/gebruikers');
      dispatch({ type: USER_UPDATE_RESET });
    }
    // eslint-disable-next-line
  }, [dispatch, match, history, userInfo, success]);

  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (userInfo._id === user._id) {
      dispatch(
        updateUserProfile({
          id: user._id,
          firstName,
          lastName,
          email,
          isAdmin: admin,
          Nixx: nixx,
        })
      );
    } else {
      dispatch(
        updateUser({
          id: user._id,
          firstName,
          lastName,
          isAdmin: admin,
          Nixx: nixx,
          email,
        })
      );
    }
  };
  const adjustNumber = (num) => {
    const adjustedNum = Number(num).toFixed(2).replace('.', ',');
    return adjustedNum;
  };

  const nixxOptions = [
    { value: true, label: 'Ja' },
    { value: false, label: 'Nee' },
  ];
  const adminOptions = [
    { value: true, label: 'Ja' },
    { value: false, label: 'Nee' },
  ];
  const swappedTopUp = topUps?.sort((a, b) => {
    const dateA = new Date(a.paidAt);
    const dateB = new Date(b.paidAt);
    return dateB - dateA;
  });

  // const swappedOrder = orders?.sort((a, b) => {
  //   const dateA = new Date(a.paidAt);
  //   const dateB = new Date(b.paidAt);
  //   return dateB - dateA;
  // });

  return (
    <div className='overflow-hidden'>
      {userSuccess ? (
        <>
          {' '}
          <>
            <Nav />
            <div className='flex justify-center'>
              <div className='w-[90vw] flex justify-end flex-col lg:flex-row'>
                {/* Gebruiker */}
                <div className='w-full  xl:w-1/3 mr-10'>
                  <form onSubmit={submitHandler}>
                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full  px-3 mb-6 md:mb-0'>
                        <h1 className='text-left font-bold text-4xl mb-4'>
                          Gebruiker
                        </h1>
                        <label className='block text-left '>
                          <span className=' font-medium text-gray-700 text-lg'>
                            Huidig saldo
                          </span>
                          <h2 className='text-5xl'>
                            {adjustNumber(user.saldo)}
                          </h2>
                        </label>
                        <label className='block text-left '>
                          <span className=' font-medium text-gray-700 text-lg'>
                            Voornaam
                          </span>
                          <input
                            type='text'
                            name='lastname'
                            className='mt-1 text-md text-bold	px-5 py-3  bg-white border border-[#D1D5DB] placeholder-gray-700 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] block w-full rounded sm:text-md focus:ring-1'
                            onChange={(e) => setFirstname(e.target.value)}
                            placeholder={user.firstName}
                          />
                        </label>
                        <label className='block text-left mt-3'>
                          <span className=' font-medium text-gray-700 text-lg'>
                            Achternaam
                          </span>
                          <input
                            type='text'
                            name='lastname'
                            className='mt-1 text-md text-bold	px-5 py-3  bg-white border border-[#D1D5DB] placeholder-gray-700 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] block w-full rounded sm:text-md focus:ring-1'
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder={user.lastName}
                          />
                        </label>
                        <label className='block text-left mt-3'>
                          <span className=' font-medium text-gray-700 text-lg'>
                            E-mail
                          </span>
                          <input
                            type='email'
                            name='email'
                            className='mt-1 text-md text-bold	px-5 py-3 bg-white border border-[#D1D5DB] placeholder-gray-700 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] block w-full rounded sm:text-md focus:ring-1'
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={user.email}
                          />
                        </label>
                        <label className='block text-left mt-3'>
                          <span className=' font-medium text-gray-700 text-lg'>
                            18+
                          </span>
                        </label>

                        <div className='relative'>
                          <select
                            className='block appearance-none w-full bg-white border border-[#D1D5DB] placeholder-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981 text-left'
                            id='grid-state'
                            key={user._id}
                            defaultValue={user.Nixx}
                            onChange={(e) => setNixx(e.target.value)}
                          >
                            <option value={true}>Ja</option>
                            <option value={false}>Nee</option>
                          </select>
                          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                            <svg
                              className='fill-current h-4 w-4'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                            >
                              <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                            </svg>
                          </div>
                        </div>
                        <label className='block text-left mt-3'>
                          <span className=' font-medium text-gray-700 text-lg'>
                            Admin
                          </span>
                        </label>
                        <div className='relative'>
                          <select
                            className='block appearance-none w-full bg-white border border-[#D1D5DB] placeholder-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981 text-left'
                            id='grid-state'
                            key={user._id}
                            defaultValue={user.isAdmin}
                            onChange={(e) => setAdmin(e.target.value)}
                          >
                            <option value={true}>Ja</option>
                            <option value={false}>Nee</option>
                          </select>
                          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                            <svg
                              className='fill-current h-4 w-4'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                            >
                              <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                            </svg>
                          </div>
                        </div>
                        <div className='w-full justify-start flex mt-10 '>
                          <div className='md:w-full w-1/2  '>
                            <button
                              className='w-full rounded h-10 bg-[#34D399] text-white font-semibold'
                              type='submit'
                              value='Wijzigen'
                            >
                              Wijzigen
                            </button>
                          </div>
                        </div>
                        {userInfo._id !== user._id ? (
                          <h2
                            onClick={() =>
                              history.push(`/gebruikers/${user._id}/reset`)
                            }
                            className='cursor-pointer'
                          >
                            Forgot Password
                          </h2>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {/* Bestellingen */}
                <div className='w-full lg:w-1/2 xl:w-1/3 mr-10 max-w-lg mt-10'>
                  <h1
                    className='text-4xl font-bold'
                    onClick={() => setShowOrders(!showOrders)}
                  >
                    Bestellingen
                  </h1>
                  {orders?.length > 0 && showOrders
                    ? orders.map((order) => (
                        <div
                          key={order._id}
                          className='shadow-md p-5 rounded-md'
                        >
                          {order.users.map((user1, index) => (
                            <div key={user1._id}>
                              {user1._id === user._id ? (
                                <>
                                  <h2 className='font-bold text-lg'>
                                    {order._id}
                                  </h2>
                                  <p>
                                    €{' '}
                                    {Number(
                                      order.productPrice * order.qtyPP[index]
                                    ) || order.pricePP}
                                  </p>
                                  <p className='text-[#10B981]'>
                                    {new Date(order.paidAt).toLocaleDateString(
                                      'nl-NL',
                                      options
                                    )}
                                  </p>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          ))}
                        </div>
                      ))
                    : null}
                </div>

                {/* Opwaarderingen */}
                <div className=' w-full lg:w-1/2 md:w-1/3 mr-10 max-w-lg mt-10'>
                  <h1
                    className='text-4xl font-bold'
                    onClick={() => setShowTopUps(!showTopUps)}
                  >
                    Opwaarderingen
                  </h1>
                  {swappedTopUp && showTopUps ? (
                    <>
                      {swappedTopUp.map((topUp) => (
                        <div key={topUp._id}>
                          {user._id === topUp.user ? (
                            <div
                              key={user._id}
                              className='shadow-md p-5 rounded-md'
                            >
                              {' '}
                              <h2 className='font-bold text-lg'>{topUp._id}</h2>
                              <p>€{topUp.topUpAmount}</p>
                              <p className='text-[#10B981]'>
                                {new Date(topUp.paidAt).toLocaleDateString(
                                  'nl-NL',
                                  options
                                )}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ))}{' '}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {/* End flex container */}
            </div>
          </>
        </>
      ) : null}
    </div>
  );
};

export default EditUser;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listOrders } from '../../actions/orderActions';
import { Orders } from '../../components/Orders';
import { TopUp } from '../../components/TopUp';
import Nav from '../../components/Nav';
import { Loader } from '../../components/Loader';
import { Link } from 'react-router-dom';
const OrderList = ({ history }) => {
  const dispatch = useDispatch();
  const orderList = useSelector((state) => state.orderList);
  const { orders, loading: orderListLoading } = orderList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo.isAdmin === false) {
      history.push('/inloggen');
    } else {
      dispatch(listOrders(userInfo.organisation));
    }
  }, [dispatch, userInfo, history]);

  return (
    <>
      <Nav />
      {orderListLoading ? (
        <Loader />
      ) : (
        <>
          <div className='flex w-[100vw] justify-center'>
            <div>
              <div className='flex flex-col mt-20'>
                <>
                  {orders.map((order) => (
                    <>
                      {order.organisation === userInfo.organisation ? (
                        <div>
                          <Link to={`/bestellingen/${order._id}/edit`}>
                            <Orders orders={order} user={userInfo} />
                          </Link>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ))}
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OrderList;

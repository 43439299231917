import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userDetailsReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userLoginReducer,
  resetPasswordReducer,
} from './reducers/userReducers';

import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
} from './reducers/productReducers.js';

import {
  topUpDetailsReducer,
  topUpUpdateReducer,
  topUpListReducer,
  getUserTopUpsReducer,
} from './reducers/topUpReducers';

import {
  orderDetailsReducer,
  orderListReducer,
  getUserOrdersReducer,
} from './reducers/orderReducers';

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  resetPassword: resetPasswordReducer,
  topUpDetails: topUpDetailsReducer,
  topUpUpdate: topUpUpdateReducer,
  topUpList: topUpListReducer,
  userTopUps: getUserTopUpsReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderDetails: orderDetailsReducer,
  orderList: orderListReducer,
  userOrders: getUserOrdersReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

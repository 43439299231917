import {
  TOPUP_DETAILS_FAIL,
  TOPUP_DETAILS_SUCCESS,
  TOPUP_DETAILS_REQUEST,
  TOPUP_LIST_FAIL,
  TOPUP_LIST_SUCCESS,
  TOPUP_LIST_REQUEST,
  TOPUP_UPDATE_REQUEST,
  TOPUP_UPDATE_SUCCESS,
  TOPUP_UPDATE_FAIL,
  TOPUP_UPDATE_RESET,
  TOPUPS_USERTOPUPS_REQUEST,
  TOPUPS_USERTOPUPS_SUCCESS,
  TOPUPS_USERTOPUPS_FAIL,
} from '../constants/topUpConstants';

export const topUpDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPUP_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOPUP_DETAILS_SUCCESS:
      return {
        loading: false,
        topUp: action.payload,
      };
    case TOPUP_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const topUpUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPUP_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case TOPUP_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case TOPUP_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOPUP_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const topUpListReducer = (state = { topUps: [] }, action) => {
  switch (action.type) {
    case TOPUP_LIST_REQUEST:
      return {
        loading: true,
      };
    case TOPUP_LIST_SUCCESS:
      return {
        loading: false,
        topUps: action.payload,
      };
    case TOPUP_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getUserTopUpsReducer = (state = { topUps: [] }, action) => {
  switch (action.type) {
    case TOPUPS_USERTOPUPS_REQUEST:
      return {
        loading: true,
      };
    case TOPUPS_USERTOPUPS_SUCCESS:
      return {
        loading: false,
        topUps: action.payload,
      };
    case TOPUPS_USERTOPUPS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

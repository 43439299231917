import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listTopUps } from '../../actions/topUpActions';
import { TopUp } from '../../components/TopUp';
import Nav from '../../components/Nav';

import { Loader } from '../../components/Loader';
const ListTopUps = ({ history }) => {
  const dispatch = useDispatch();
  const topUpList = useSelector((state) => state.topUpList);
  const { topUps, loading: topUpLoading } = topUpList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo.isAdmin) {
      dispatch(listTopUps(userInfo.organisation));
    }
  }, [dispatch, userInfo, history]);

  return (
    <>
      <Nav />
      {topUpLoading ? (
        <Loader />
      ) : (
        <>
          <div className='flex w-[100vw] justify-center'>
            <div>
              <div className='flex flex-col mt-20'>
                <>
                  {topUps.map((topup) => (
                    <>
                      <div>
                        <div>
                          <TopUp topUp={topup} />
                        </div>
                      </div>
                    </>
                  ))}
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ListTopUps;

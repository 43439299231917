import React from 'react';

export const Product = ({ product, user }) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return (
    <div className=''>
      {user.organisation === product.organisation ? (
        <div className='w-full mt-8 grid '>
          <h1 className='text-3xl font-bold'>{product.name}</h1>
          <h3 className='text-2xl mt-2 font-medium'>{product.price}</h3>
          <h3 className='text-gray-300 font-medium justify-end'>
            {new Date(product.createdAt).toLocaleDateString('nl-NL', options)}
          </h3>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

import { PlusIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';
const Card = ({ title, link }) => {
  return (
    <div className=' w-28 h-28  rounded-xl shadow-md grid break-words'>
      <Link to={link} className='grid place-content-center break-all'>
        <h2 className='font-semibold text-md text-center '>{title}</h2>
      </Link>
    </div>
  );
};

export default Card;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProduct,
  listProductDetails,
  updateProduct,
} from '../../actions/productActions';
import {
  PRODUCT_DETAILS_RESET,
  PRODUCT_UPDATE_RESET,
} from '../../constants/productConstants';
import Nav from '../../components/Nav';

const EditProduct = ({ history, match }) => {
  const dispatch = useDispatch();
  const productId = match.params.id;
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const productUpdate = useSelector((state) => state.productUpdate);
  const { success: successUpdate } = productUpdate;

  const [productName, setProductName] = useState(product.name);
  const [productPrice, setProductPrice] = useState(product.price);
  const [productCategory, setProductCategory] = useState(product.category);
  const [nixx, setNixx] = useState(product.Nixx);
  const [descLong, setDescLong] = useState(product.descLong);
  const [descShort, setDescShort] = useState(product.descShort);

  useEffect(() => {
    dispatch({ type: PRODUCT_DETAILS_RESET });
    if (!userInfo?.isAdmin) {
      history.push('/inloggen');
    } else {
      dispatch(listProductDetails(productId));
    }

    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      history.push('/producten');
    }
  }, [dispatch, history, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        name: productName,
        price: productPrice,
        category: productCategory,
        descShort,
        descLong,
        organisation: userInfo.organisation,
        nixx,
        updatedBy: userInfo.firstName + userInfo.lastName,
      })
    );
  };
  const removeItemHandler = (id) => {
    dispatch(deleteProduct(id));
    history.push('/producten');
  };

  return (
    <div className='md:overflow-hidden overflow-x-hidden'>
      <Nav />
      <div className='flex w-[95%] lg:w-[100vw]'>
        <div className='lg:w-1/3 hidden '></div>
        <div className='flex flex-row w-[95%] lg:w-full md:ml-10 '>
          {product ? (
            <div className='flex items-center md:h-[100vh]'>
              <form className='flex flex-col w-[95%] lg:flex-row items-center mx-auto md:w-2/3  mt-10'>
                <div className='lg:mr-36 w-[95%] md:w-[90%]'>
                  <h1 className='text-5xl font-bold mb-0 md:mb-10 '>
                    Product aanpassen
                  </h1>
                  <div className='flex flex-wrap  -mx-3 mb-6'>
                    <div className='flex w-full my-5 px-3 items-center'>
                      <div className='w-20 h-20 shadow-md rounded-xl'></div>
                      <button
                        disabled
                        className='bg-[#34D399] rounded-md mx-5 p-2 w-36 h-10 text-white '
                      >
                        Voeg foto toe
                      </button>
                    </div>
                    <div className='w-full  px-3 mb-6 md:mb-0'>
                      <label
                        className='block tracking-wide text-black text-md font-bold mb-2 text-left'
                        htmlFor='grid-first-name'
                      >
                        Productnaam
                      </label>
                      <input
                        className='appearance-none block w-[90vw] md:w-full bg-[#F3F4F6] text-gray-700  placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                        id='grid-first-name'
                        type='text'
                        placeholder={product.name}
                        onChange={(e) => setProductName(e.target.value)}
                      />
                    </div>

                    <div className='w-1/4  px-3'>
                      <label
                        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
                        htmlFor='grid-last-name'
                      >
                        Prijs
                      </label>
                      <input
                        className='appearance-none block w-[90vw] md:w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 leading-tight   '
                        id='grid-last-name'
                        type='text'
                        onChange={(e) => setProductPrice(e.target.value)}
                        placeholder={product.price}
                      />
                    </div>
                  </div>
                  <div className='relative'>
                    <select
                      className='appearance-none block w-[90vw] md:w-full bg-[#F3F4F6] text-gray-700  placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                      id='grid-state'
                      key={product._id}
                      defaultValue={product.category}
                      onChange={(e) => setProductCategory(e.target.value)}
                    >
                      <option value={'Alcoholische dranken'}>
                        Alcoholische dranken
                      </option>
                      <option value={'Dranken'}>Dranken</option>
                      <option value={'Snacks'}>Snacks</option>
                      <option value={'Eten'}>Eten</option>
                    </select>
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                      <svg
                        className='fill-current h-4 w-4'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                      >
                        <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                      </svg>
                    </div>
                  </div>
                  <label className='block text-left mt-3'>
                    <span className=' font-medium text-gray-700 text-lg'>
                      18+
                    </span>
                  </label>
                  <div className='relative'>
                    <select
                      className='appearance-none block w-[90vw] md:w-full bg-[#F3F4F6] text-gray-700  placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                      id='grid-state'
                      key={product._id}
                      defaultValue={product.Nixx}
                      onChange={(e) => setNixx(e.target.value)}
                    >
                      <option value={true}>Ja</option>
                      <option value={false}>Nee</option>
                    </select>
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                      <svg
                        className='fill-current h-4 w-4'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                      >
                        <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className='w-[95vw] lg:w-[40vw] mb-10 md:mb-0'>
                  <div className='md:w-full flex flex-col mb-6 md:mb-0'>
                    <label
                      className='block tracking-wide ml-[2rem] md:ml-[9rem] text-black text-md font-bold mb-2 text-left'
                      htmlFor='grid-desc-short'
                    >
                      Beschrijving kort
                    </label>
                    <textarea
                      className='md:w-[60%] appearance-none self-center block w-[90vw] lg:w-[30vw] bg-[#F3F4F6] text-gray-700  placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                      id='grid-desc-short'
                      onChange={(e) => setDescShort(e.target.value)}
                      placeholder={product.descShort}
                    />
                  </div>
                  <div className='w-[100vw] flex flex-col lg:w-full  mb-6 md:mb-0'>
                    <label
                      className='md:ml-[9rem] block ml-[2rem]  tracking-wide text-black text-md font-bold mb-2 text-left'
                      htmlFor='grid-desc-long'
                    >
                      Beschrijving lang
                    </label>
                    <textarea
                      className='md:w-[60%] appearance-none self-center block w-[90vw] lg:w-[30vw] bg-[#F3F4F6] text-gray-700  placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3  mb-3 leading-tight'
                      id='grid-desc-long'
                      onChange={(e) => setDescLong(e.target.value)}
                      placeholder={product.descLong}
                    />
                  </div>
                  <div className=' w-[100vw] md:w-full flex flex-col items-center '>
                    <div className='flex items-center md:w-[50%]  mt-10'>
                      <input
                        type='submit'
                        className='appearance-none block rounded bg-[#10B981] lg:w-[20vw] w-[90vw] md:w-full align-middle text-center py-3 leading-tight text-white font-bold'
                        value='Doorgaan'
                        onClick={submitHandler}
                      ></input>
                    </div>
                    <div className='flex items-center md:w-[50%]  mt-10'>
                      <input
                        type='submit'
                        className='appearance-none block rounded bg-[#e43030] w-[90vw] lg:w-[20vw] md:w-full align-middle text-center py-3 leading-tight text-white font-bold'
                        value='Verwijder Product'
                        onClick={() => removeItemHandler(product._id)}
                      ></input>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditProduct;

import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { logout } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../actions/userActions';
const Nav = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    if (!userInfo) {
      dispatch(getUserDetails('profile'));
    }
  }, [dispatch, userInfo, user]);

  const logoutHandler = () => {
    dispatch(logout());
    history.push('/inloggen');
  };
  return (
    <nav className=' flex items-center justify-between flex-wrap bg-white p-6 w-[100vw]'>
      <Link
        to='/'
        className='flex items-center flex-shrink-0 text-[#10B981] text-shadow mr-6 text-4xl font-bold'
      >
        BarApp
      </Link>
      <Link
        to='/uitloggen'
        className='mt-4 lg:inline-block lg:mt-0 text-black font-medium text-xl hover:text-[#10B981] md:invisible'
      >
        Uitloggen
      </Link>
      <div className='hidden w-[80%] md:block flex-end sm:hidden  lg:flex'>
        <div className='text-sm lg:flex lg:justify-between lg:w-full font-semibold right-2 w-[80%] '>
          <Link
            to='/gebruikers'
            className=' mt-4 lg:inline-block lg:mt-0 text-black hover:text-[#10B981] mr-10 text-xl'
          >
            Gebruikers
          </Link>
          <Link
            to='/producten'
            className=' mt-4 lg:inline-block lg:mt-0 text-black hover:text-[#10B981] mr-10 text-xl'
          >
            Producten
          </Link>

          <Link
            to='/opwaarderen'
            className='block mt-4 lg:inline-block lg:mt-0 text-black mr-10 hover:text-[#10B981] text-xl'
          >
            Opwaardingen
          </Link>
          <Link
            to='/bestellingen'
            className='block mt-4 lg:inline-block lg:mt-0 text-black mr-10 hover:text-[#10B981] text-xl'
          >
            Bestellingen
          </Link>

          <Link
            onClick={logoutHandler}
            className='block mt-4 lg:inline-block lg:mt-0 text-black mr-10 hover:text-[#10B981] text-xl'
          >
            Uitloggen
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;

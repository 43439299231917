import { PlusIcon } from '@heroicons/react/outline';
import Nav from '../../components/Nav';
import { Product } from '../../components/Product';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listProducts,
  deleteProduct,
  createProduct,
} from '../../actions/productActions';
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DETAILS_RESET,
} from '../../constants/productConstants';
import { Loader } from '../../components/Loader';
import { Link } from 'react-router-dom';

const ProductList = ({ history }) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createProductHandler = () => {
    dispatch(createProduct(userInfo.organisation));
  };

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });
    dispatch({ type: PRODUCT_DETAILS_RESET });
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/inloggen');
    }
    if (successCreate) {
    } else {
      dispatch(listProducts(userInfo.organisation));
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    createdProduct,
    successCreate,
  ]);
  return (
    <div className='overflow-hidden'>
      <Nav />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className='flex w-[100vw] justify-center'>
            <div>
              <div className='flex flex-col mt-20'>
                <div className='flex flex-row w-full justify-items-center '>
                  <div
                    className='w-80 h-12 bg-[#10B981] rounded-md grid place-items-center text-white cursor-pointer'
                    onClick={createProductHandler}
                  >
                    <h3 className='inline font-bold'>
                      <PlusIcon className='inline w-7 mr-2' /> Product toevoegen
                    </h3>
                  </div>
                </div>
                <div className='flex flex-col ml-10  mt-10 h-full overflow-y-auto '>
                  {products?.map((product) => (
                    <>
                      {product.organisation === userInfo.organisation ? (
                        <Link to={`/producten/${product._id}/edit`}>
                          <Product product={product} user={userInfo} />
                        </Link>
                      ) : (
                        ''
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductList;

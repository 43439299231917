function Alert({ message }) {
  return (
    <div
      className='p-4 block mx-auto  sm:w-[90vw] md:w-[100%]  mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
      role='alert'
    >
      <span className='font-medium flex justify-center align-middle'>
        {message}
      </span>
    </div>
  );
}

export default Alert;

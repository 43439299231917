import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../actions/userActions';
import Nav from '../../components/Nav';
const CreateUser = ({ history }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [password, setPassword] = useState('');
  const [nixx, setNixx] = useState('');
  const sortedEmail = email.toLowerCase();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      register({
        firstName,
        lastName,
        organisation: userInfo.organisation,
        email: sortedEmail,
        password,
        nixx,
        isOwner: false,
        isAdmin,
      })
    );
  };
  return (
    <>
      <Nav />
      <div className='grid align-center text-center  place-content-center h-full md:h-[100vh]'>
        <h1 className='text-4xl font-bold mb-10 max-w-[94vw] md:w-full'>
          Gebruiker aanmaken
        </h1>
        <form className='w-[90vw] mx-auto md:w-full max-w-lg mt-10 h-full'>
          <div className='flex flex-wrap -mx-3 mb-6'>
            <div className='w-full  px-3 mb-6 md:mb-0'>
              <label
                className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
                htmlFor='grid-first-name'
              >
                Voornaam
              </label>
              <input
                className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                id='grid-first-name'
                type='text'
                placeholder='Eva'
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>
            <div className='w-full  px-3'>
              <label
                className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
                htmlFor='grid-last-name'
              >
                Achternaam
              </label>
              <input
                className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 leading-tight   '
                id='grid-last-name'
                type='text'
                placeholder='Schippers'
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
          </div>
          <div className='flex flex-wrap -mx-3 mb-6'>
            <div className='w-full  px-3 mb-6 md:mb-0'>
              <label
                className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
                htmlFor='grid-first-name'
              >
                Email
              </label>
              <input
                className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                id='grid-first-name'
                type='email'
                placeholder='De email van de eigenaar'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
          <div className='flex flex-wrap -mx-3 mb-6'>
            <div className='w-full  px-3 mb-6 md:mb-0'>
              <label
                className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
                htmlFor='grid-first-name'
              >
                Wachtwoord
              </label>
              <input
                className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                id='grid-first-name'
                type='password'
                placeholder='Doe maar iets sterks'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
          </div>

          <div className='w-full md:w-1/4 mb-6 md:mb-0'>
            <label
              className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
              htmlFor='grid-state'
            >
              18+
            </label>
            <div className='relative'>
              <select
                className='block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981 text-left'
                id='grid-state'
                onChange={(e) => setNixx(e.target.value)}
              >
                <option value={true}>Ja</option>
                <option value={false}>Nee</option>
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>
          {userInfo.isOwner && (
            <div className='w-full md:w-1/4 mb-6 md:mb-0'>
              <label
                className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
                htmlFor='grid-state'
              >
                Is het een admin?
              </label>
              <div className='relative'>
                <select
                  className='block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981 text-left'
                  id='grid-state'
                  onChange={(e) => setIsAdmin(e.target.value)}
                >
                  <option value={true}>Ja</option>
                  <option value={false}>Nee</option>
                </select>
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                  <svg
                    className='fill-current h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                  </svg>
                </div>
              </div>
            </div>
          )}
          <div className='flex items center space-x-6 max-w-lg mx-auto mt-10'>
            <input
              type='submit'
              className='appearance-none block rounded bg-[#10B981] w-[30rem] align-middle text-center py-3 px-4 leading-tight text-white font-bold'
              value='Doorgaan'
              onClick={submitHandler}
            ></input>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateUser;

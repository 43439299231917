import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import ProductList from './pages/products/ProductList';
import EditProduct from './pages/products/EditProduct';
import UserList from './pages/users/UserList';
import CreateUser from './pages/users/CreateUser';

import EditUser from './pages/users/EditUser';
import ListTopUps from './pages/topUp/ListTopUps';
import EditTopUp from './pages/topUp/EditTopUp';
import OrderList from './pages/orders/OrderList';
import EditOrder from './pages/orders/EditOrder';
import Logout from './pages/Logout';
import ForgotPassword from './pages/users/ForgotPassword';

function App() {
  return (
    <Router>
      <Route path='/' component={Home} exact />
      <Route path='/inloggen' component={Login} exact />
      <Route path='/uitloggen' component={Logout} />
      <Route path='/producten' component={ProductList} exact />
      <Route path='/producten/:id/edit' component={EditProduct} exact />
      <Route path='/gebruikers' component={UserList} exact />
      <Route path='/gebruikers/:id/edit' component={EditUser} exact />
      <Route path='/gebruikers/aanmaken' component={CreateUser} exact />
      <Route path='/gebruikers/:id/reset' component={ForgotPassword} exact />
      <Route path='/bestellingen' component={OrderList} exact />
      <Route path='/bestellingen/:id/edit' component={EditOrder} exact />{' '}
      <Route path='/opwaarderen' component={ListTopUps} exact />
      <Route path='/opwaarderen/:id/edit' component={EditTopUp} exact />
    </Router>
  );
}

export default App;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserDetails } from '../actions/userActions';
export const TopUp = ({ topUp, userInfo }) => {
  // const dispatch = useDispatch();
  // const userDetails = useSelector((state) => state.userDetails);
  // const { loading, error, user } = userDetails;
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return (
    <div className=''>
      <Link to={`opwaarderen/${topUp._id}/edit`} className='w-full mt-8 grid '>
        <h1 className='text-3xl font-bold'>
          {new Date(topUp.createdAt).toLocaleDateString('nl-NL', options)}
        </h1>

        <h3 className='text-gray-300 font-medium justify-end'>{topUp._id}</h3>
      </Link>
    </div>
  );
};

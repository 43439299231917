import React from 'react';

export const Orders = ({ orders, user }) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return (
    <div className=''>
      {user.organisation === orders.organisation ? (
        <div className='w-full mt-8 grid '>
          <h1 className='text-3xl font-bold'>
            {new Date(orders.createdAt).toLocaleDateString('nl-NL', options)}
          </h1>
          <h3 className='text-2xl mt-2 font-medium'>€{orders.totalPrice}</h3>
          <h3 className='text-gray-300 font-medium justify-end'>
            {orders._id}
          </h3>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../actions/userActions';
import Nav from '../components/Nav';
import Card from '../components/Card';
const Home = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    if (!userInfo) {
      dispatch(getUserDetails('profile'));
    }
    if (!userInfo?.isAdmin) {
      history.push('/inloggen');
    }
    // if (!userInfo.isAdmin) {
    //   window.location.replace('https://bar.barapp.nl');
    // }
  }, [dispatch, userInfo, history, user]);

  return userInfo && user ? (
    <div className='overflow-hidden'>
      <Nav />
      <div className='flex w-[100vw] justify-items-center items-center h-[80vh] '>
        <div className='flex flex-row mx-auto md:ml-5'>
          <div>
            <div className='w-full md:w-3/4 md:hidden  block mx-auto'>
              <div className='flex flex-col mb-20 '>
                <div className=' flex flex-row w-full h-5  md:ml-10 space-y-32 '>
                  <h1 className=' text-2xl md:text-4xl font-bold'>
                    Wat zou je willen doen
                  </h1>
                </div>

                <div className='mt-10 grid grid-cols-2 gap-y-8 justify-centerd	 md:flex md:flex-row md:ml-10 md:space-x-12 md:mt-10 md:h-full '>
                  <Card title='Gebruikers' link='/gebruikers' />
                  <Card title='Producten' link='/producten' />
                  <Card title='Opwaarderingen' link='/opwaarderen' />
                  <Card title='Bestellingen' link='/bestellingen' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default Home;

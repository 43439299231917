import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, resetPassword } from '../../actions/userActions';
import Nav from '../../components/Nav';

const ForgotPassword = ({ history, match }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [password, setPassword] = useState('');
  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getUserDetails(match.params.id));
    } else {
      history.push('/inloggen');
    }
  }, [dispatch, match, history, userInfo]);
  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(user._id);
    dispatch(
      resetPassword({
        id: user._id,
        password,
      })
    );
  };

  return (
    <div>
      <div className='overflow-hidden'>
        {user ? (
          <>
            {' '}
            <>
              <Nav />
              <div className='flex justify-center'>
                <div className='w-[90vw] flex justify-center flex-col lg:flex-row'>
                  <form action=''>
                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full  px-3 mb-6 md:mb-0'>
                        <h1 className='text-left font-bold text-4xl mb-4'>
                          Gebruiker
                        </h1>
                        <label className='block text-left '>
                          <span className=' font-medium text-gray-700 text-lg'>
                            Wachtwoord Wijzigen
                          </span>
                          <input
                            className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='grid-last-name'
                            type='password'
                            placeholder='Doe maar iets sterks'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </label>
                        <button
                          className='w-full rounded mt-5 h-10 bg-[#34D399] text-white font-semibold'
                          type='submit'
                          value='Wijzigen'
                          onClick={submitHandler}
                        >
                          Wijzigen wachtwoord
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;

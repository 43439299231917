import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Nav from '../../components/Nav';
import { getTopUpDetails } from '../../actions/topUpActions';

import { getUserDetails } from '../../actions/userActions';
import { Loader } from '../../components/Loader';

const EditTopUp = ({ match }) => {
  const dispatch = useDispatch();
  const topUpDetails = useSelector((state) => state.topUpDetails);
  const { loading, error, topUp } = topUpDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const { userInfo } = userLogin;
  const id = match.params.id;
  useEffect(() => {
    if (userInfo.isAdmin) {
      dispatch(getTopUpDetails(id));
    }
    if (topUp) {
      dispatch(getUserDetails(topUp.user));
    }
  }, [dispatch, id, userInfo]);

  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return (
    <div className='overflow-hidden relative'>
      <Nav />
      {topUp ? (
        <div className='relative'>
          <div className='  grid justify-center items-center h-[100vh] w-full'>
            <div className='w-full flex justify-center flex-row'>
              {/* Gebruiker */}
              <div className=''>
                <div className='flex flex-wrap mb-6'>
                  <div className='w-full  mb-6 md:mb-0'>
                    <h1 className='text-left font-bold text-5xl mb-4'>
                      Opwaardering
                    </h1>
                  </div>
                  <h2 className='text-2xl'>{topUp._id}</h2>
                </div>
                <div className='grid grid-flow-col mb-6'>
                  <div>
                    <h2 className='text-3xl font-bold'>Datum</h2>
                    <p className='text-xl'>
                      {new Date(topUp.createdAt).toLocaleDateString(
                        'nl-NL',
                        options
                      )}
                    </p>
                  </div>
                  <div>
                    <h2 className='text-3xl font-bold'>Bedrag</h2>
                    <p className='text-xl'>€{topUp.topUpAmount}</p>
                  </div>
                </div>
                <div>
                  <h2 className='text-3xl font-bold'>Bestellende</h2>
                  <p className='text-xl'>
                    {user.firstName + ' ' + user.lastName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default EditTopUp;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
function Logout({ history }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
    history.push('/inloggen');
  }, [dispatch, history]);

  return <div>Logout</div>;
}

export default Logout;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Nav from '../../components/Nav';
import { getOrderDetails } from '../../actions/orderActions';
import { getUserDetails } from '../../actions/userActions';
import { Loader } from '../../components/Loader';
const EditOrder = ({ match, history }) => {
  const dispatch = useDispatch();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { loading, error, order } = orderDetails;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const id = match.params.id;
  useEffect(() => {
    if (!order) {
      dispatch(getOrderDetails(id));
    }
    if (order) {
      dispatch(getUserDetails(order.users.map((user) => user)));
    }
  }, [order, dispatch, id]);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return (
    <div className='overflow-hidden'>
      <Nav />
      {order ? (
        <>
          <div className=''>
            <div className='grid align-center  place-content-center h-[100vh]'>
              <h1 className='text-4xl font-bold mb-10 w-full'>
                {new Date(order.createdAt).toLocaleDateString('nl-NL', options)}
              </h1>
              <h3 className='text-gray-400 font-xl font-medium'>Ordernummer</h3>
              <h2 className='text-left text-xl mb-1 w-full'>{order._id}</h2>
              <form className='w-full max-w-lg mt-5'>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full  px-3 mb-6 md:mb-0'>
                    <h1 className='text-gray-400 font-xl font-medium'>
                      {' '}
                      Gebruiker
                    </h1>

                    {order?.users.map((users) => (
                      <>
                        <div>
                          <h1 className='text-left text-2xl'>
                            {user.firstName + ' ' + user.lastName}{' '}
                          </h1>
                        </div>
                      </>
                    ))}
                    {/* <h1 className=' text-left text-xl'> {order.user.email}</h1> */}
                  </div>
                  <div className='w-full mt-4 px-3'>
                    <h1 className='text-gray-400 font-xl font-medium'>
                      {' '}
                      Hoeveelheid
                    </h1>
                    <h1 className=' text-left text-xl'>{order.qty}</h1>
                  </div>
                  <div className='w-full  px-3'>
                    <h1 className='text-gray-400 font-xl font-medium'>
                      {' '}
                      Totaalbedrag
                    </h1>
                    <h1 className=' text-left text-xl'> €{order.totalPrice}</h1>
                  </div>
                </div>
                {order.isPaid === true ? (
                  <div className='w-full'>
                    <h1 className='text-gray-400 font-xl font-medium'>
                      {' '}
                      Betaald op
                    </h1>
                    <h1 className=' text-left text-xl'>
                      {new Date(order.paidAt).toLocaleDateString(
                        'nl-NL',
                        options
                      )}
                    </h1>
                  </div>
                ) : (
                  ''
                )}
                {order.isPaid === false ? (
                  <>
                    <div className='relative'>
                      <h1 className='text-left font-bold text-2xl'>Betaald</h1>
                      <select
                        className='block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981 text-left'
                        id='grid-state'
                      >
                        <option value={true}>Ja</option>
                        <option value={false}>Nee</option>
                      </select>
                      <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                        <svg
                          className='fill-current h-4 w-4'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                        >
                          <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                        </svg>
                      </div>
                    </div>
                    <div className='flex items center space-x-6 max-w-lg mx-auto mt-10'>
                      <input
                        type='submit'
                        className='appearance-none block rounded bg-[#10B981] w-[30rem] align-middle text-center py-3 px-4 leading-tight text-white font-bold'
                        value='Doorgaan'
                        //onClick={submitHandler}
                      ></input>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </form>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default EditOrder;

import {
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_USERORDERS_REQUEST,
  ORDER_USERORDERS_SUCCESS,
  ORDER_USERORDERS_FAIL,
  ORDER_USERORDERS_RESET,
} from '../constants/orderConstants';

export const orderDetailsReducer = (
  state = { loading: true, orderItems: [] },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const getUserOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_USERORDERS_REQUEST:
      return {
        loading: true,
      };
    case ORDER_USERORDERS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_USERORDERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_USERORDERS_RESET:
      return {
        loading: false,
        orders: {},
      };
    default:
      return state;
  }
};

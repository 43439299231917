import React from 'react';
import { Link } from 'react-router-dom';

const User = ({ user }) => {
  return (
    <div className='w-[20rem] h-40 mt-8 grid grid-rows-3 bg-[#34D399] text-white rounded-xl '>
      <h1 className='text-3xl font-bold mt-8 ml-8'>
        {user.firstName + ' ' + user.lastName}
      </h1>
      <h3 className='mt-5 ml-8'>€{Number(user.saldo).toFixed(2)}</h3>
      <div className='w-full pr-4 pb-3 self-end'>
        <Link to={`/gebruikers/${user._id}/edit`}>
          <p className='text-right w-full '>Bewerken</p>
        </Link>
      </div>
    </div>
  );
};

export default User;
